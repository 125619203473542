import React, { useState, useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {  useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useLocation  } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { Button as RtButton} from "reactstrap";
import { Slide, Paper, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Sprites from './../../static/img/sprites/logolist.png';
import Logo from './../../static/logo.png';
import LogoBlack from './../../static/logoblack.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AuthFirebaseContext } from '../../Context/AuthFirebaseContext';
import Badge from '@mui/material/Badge'
import { Link } from "react-router-dom";
import "./style.css";
import Crm from "../../static/pdts/CRM.png";

import CrmBlack from "../../static/img/pdt/crm-white.png";
import CrmWhite from "../../static/img/pdt/crm-black.png";
import HrBlack from "../../static/img/pdt/hrm-black.png";
import HrWhite from "../../static/img/pdt/hrm-white.png";
import BooksBlack from "../../static/img/pdt/books-white-bl.png";
import BooksWhite from "../../static/img/pdt/books-black.png";
import CamBlack from "../../static/img/pdt/cam-black.png";
import CamWhite from "../../static/img/pdt/cam-white.png";
import FormsBlack from "../../static/img/pdt/form-white.png";
import FormsWhite from "../../static/img/pdt/form-black.png";
import InBlack from "../../static/img/pdt/inventory-black.png";
import InWhite from "../../static/img/pdt/inventory-white.png";
import PosBlack from "../../static/img/pdt/pos-black.png";
import PosWhite from "../../static/img/pdt/pos-white.png";
import TodoBlack from "../../static/img/pdt/todo-black.png";
import TodoWhite from "../../static/img/pdt/todo-white.png";


import ThemeSwitchNew from "../../component/ToolKits/ThemeSwitchNew"
import "./apptabs.css";

const items = [
  { title: "CRM", description: "A comprehensive platform for customer facing teams", iconLight: CrmWhite, iconDark: CrmBlack, alt:"describe this logo" },
  { title: "HRM", description: "A detailed human resource management application for hr team", iconLight: HrWhite, iconDark: HrBlack , alt:"describe this logo"},
  { title: "Books & Journals", description: "A detailed human resource management application for hr team", iconLight: BooksBlack, iconDark: BooksWhite , alt:"describe this logo"},
  { title: "Inventory", description: "A detailed human resource management application for hr team", iconLight: InWhite, iconDark: InBlack , alt:"describe this logo"},
  { title: "Point of Sale", description: "A detailed human resource management application for hr team", iconLight: PosWhite, iconDark: PosBlack , alt:"describe this logo"},
  { title: "Campaigns", description: "A detailed human resource management application for hr team", iconLight: CamWhite, iconDark: CamBlack , alt:"describe this logo"},
  { title: "Todo & Task", description: "A detailed human resource management application for hr team", iconLight: TodoWhite, iconDark: TodoBlack , alt:"describe this logo"},
  { title: "Forms", description: "A detailed human resource management application for hr team", iconLight: FormsWhite, iconDark: FormsBlack , alt:"describe this logo"},
];

const AppTabs = () => {
  const [activeTab, setActiveTab] = useState(1); // Initial tab state
  const theme = useTheme();

  const handleTabClicks = (event) => {
    const newActiveTab = parseInt(event.currentTarget.getAttribute('href').slice(1));
    setActiveTab(newActiveTab);
  };


  const handleTabClick = (id) => {
    //const newActiveTab = parseInt(event.currentTarget.getAttribute('href').slice(1));
    setActiveTab(id);
    //console.log(theme);
    //console.log(id);

    //rgb(213,228,242)  //D5E4F2
  };

  return (
    <Box className="tabs" component="div" sx={{minHeight:"100%"}}>
      <Box component="ul"  className="tabs-title" sx={{ borderRight: 1, borderColor: 'divider', height:"100%", maxHeight:"100%" }}>
        <Typography component="li" className="tabs-title-item" onMouseEnter={()=>handleTabClick(1)} onMouseLeave={()=>handleTabClick(1)} onClick={()=>handleTabClick(1)} sx={{color: theme.palette.text.primary}}>ERP Solution</Typography>
        <Typography className="tabs-title-item" onMouseEnter={()=>handleTabClick(2)} onMouseLeave={()=>handleTabClick(2)} onClick={()=>handleTabClick(2)} sx={{color: theme.palette.text.primary}}>Banking Software</Typography>
        <Typography className="tabs-title-item" onMouseEnter={()=>handleTabClick(3)} onMouseLeave={()=>handleTabClick(3)} onClick={()=>handleTabClick(3)} sx={{color: theme.palette.text.primary}}>Software Development</Typography>
        <Typography className="tabs-title-item" onMouseEnter={()=>handleTabClick(4)} onMouseLeave={()=>handleTabClick(4)} onClick={()=>handleTabClick(4)} sx={{color: theme.palette.text.primary}}>Web Solutions</Typography>
      </Box>
      <Box className="tabs-content" sx={{ pl:4, maxWidth:"100%", width:"100%",overflow:"hidden"}}>
        <Box className={`activeTab ${activeTab === 1 ? "active" : ""} `}>
          <Typography variant='body2' component="p" sx={{color: theme.palette.text.primary, fontFamily:"Exo 2", fontSize:"30px"}}>ERP Solutions</Typography>
          
          <Box sx={{ mt: 2, height:"calc(100dvh - 200px)", overflowX:"scroll", width:"100%",'&::-webkit-scrollbar': { 
                        height: "5px",
                        width: "5px",
                      },
              '&::-webkit-scrollbar:hover ': { 
                        height: "10px",
                        width: "10px",
                      },
              '&::-webkit-scrollbar-thumb': { 
                      backgroundColor: "rgb(224, 224, 224)",
                      },
              '&::-webkit-scrollbar-thumb:hover': { 
                      backgroundColor: "#e0e0e0 ",
                      },
              '&:hover::-webkit-scrollbar-corner': { 
                      width: "40px",
                      }}}>
            <Grid container spacing={2}>
              
              {items.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.title}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <Box sx={{ pr: 2 }}>
                            <img
                            src={theme.palette.mode === "light" ? item.iconLight : item.iconDark}
                            alt={item.alt}
                            height="40px"
                            />
                        </Box>
                        <Box sx={{ pl: 2, pb: 2, pr: 2, userSelect: "none" }}>
                            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontFamily: "Exo 2", fontSize: "24px" }}>
                            {item.title}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                            {item.description}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
              ))}
            
            </Grid>
          </Box>
        
        </Box>
        <Box  className={`activeTab ${activeTab === 2 ? "active" : ""} `}>
          <Typography>This is the second panel of the basic tab example.</Typography>
        </Box>
        <Box  className={`activeTab ${activeTab === 3 ? "active" : ""} `}>
          <Typography>This is the third panel of the basic tab example.</Typography>
        </Box>
        <Box  className={`activeTab ${activeTab === 4 ? "active" : ""} `}>
          <Typography>This is the fourth panel of the basic tab example.</Typography>
        </Box>
      </Box>
    </Box>
  );
};


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box component="div">{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const AppsMemu = styled(Box)(
  ({ theme }) => `
    position: absolute;
    top: 70px; /* set the initial top value */
    right: 10px;
    padding: 10px 10px;
    width: 436px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    transform: translateY(40px); /* move the element down by 40px */
    will-change: transform, opacity; /* indicate that the element will be transformed and have opacity change */

    :after {
      content: "";
      position: absolute;
      top: 5;
      right: 28px;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: rotate(45deg);
    }

    & .activeApp {
      box-shadow: 0 -1px 2px 0 rgba(0,0,0,.3), 0 -2px 6px 2px rgba(0,0,0,.15);
      visibility: visible;
      opacity: 1;
      transform: translateY(0); /* move the element back to its original position */
    }
`,
);

const StyledMenu = styled((props) => (
  <Menu
    elevation={10}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 180,
    maxWidth: 350,
    overflowY: 'scroll',
    height: 200,
    maxHeight: 200,
    backgroundColor:'#343a40',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },'& .MuiPaper-root::-webkit-scrollbar': {
    width: '5px', // Set the desired width for the scrollbar
  },
  '& .MuiPaper-root::-webkit-scrollbar-thumb': {
    background: '#888', // Set the color of the scrollbar thumb
    borderRadius: '5px', // Set the border radius of the thumb
  },
  '& .MuiPaper-root::-webkit-scrollbar-track': {
    background: 'none', // Set the background color of the scrollbar track
  },
  '& .MuiPaper-root::-webkit-scrollbar-thumb:hover': {
    background: '#555', // Set the color on hover
  },
  '& .MuiPaper-root::-webkit-scrollbar-thumb:active': {
    background: '#333', // Set the color on active state
  },
}));
function ElevationScroll(props) {
    const { children, window  } = props;
    const theme = useTheme();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    const [triggerToAppbar, setTriggerToAppBar] = useState(trigger);
    
    //triggerRequest(trigger);
    //console.log(theme.palette.mode);
    
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      sx: { backgroundColor: trigger ? (theme.palette.mode === 'dark' ? theme.palette.background.paper : 'rgba(255, 255, 255, 1)') : ('rgba(255, 255, 255, 0)'),
            transition: 'background-color 500ms ease-in-out',
            color: theme.palette.text.primary
          }
    });
}


const drawerWidth = 240;
//const navItems = ['Home', 'About', 'Contact'];
const navItems = [
  { id: 1, idnm: '/', navheading: 'Home' },
  { id: 2, idnm: 'products', navheading: 'Products' },
  { id: 3, idnm: 'features', navheading: 'Features' },
  { id: 4, idnm: 'client', navheading: 'Client' },
  { id: 5, idnm: 'team', navheading: 'Team' },
  { id: 6, idnm: 'faq', navheading: 'FAQ' },
  { id: 7, idnm: 'pricing', navheading: 'Pricing' },
  { id: 8, idnm: 'contact', navheading: 'Contact' },
];

export default function NavAppBar(props) {
    
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [idMenuOpen, setIdMenuOpen] = useState(false);
    const [productsMenu, SetProductMenu] = useState(false);
    const [appsMenuOpen, setAppsMenuOpen] = useState(false);
    const [triggerfromElevation, setTriggerfromElevation] = useState(false);
    const [isSlideUpOpen, setIsSlideUpOpen] = useState(false);
    const [anchorApp, setAnchorApp] = React.useState(null);
    const { user } = useContext(AuthFirebaseContext);
    const openApp = Boolean(anchorApp);

    const [navbarColor, setNavbarColor] = useState('#f3f3f3');
    const { t } = useTranslation();
    const trigger = useScrollTrigger({ threshold: 0, target: window ? window() : undefined,});
    const theme = useTheme();
    const { pathname,hash  } = useLocation();

    const activeMenuItem = navItems.find((item) => item.idnm === hash.substring(1));

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
  // console.log(pathname);
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };

    const triggerStateFetch = (trigger) => {
      setTriggerfromElevation(trigger); 
    }
    
    const toggleDiv = () => {
      setIsSlideUpOpen(true);
    };

    const handleClickApp = (event) => {
      setAnchorApp(event.currentTarget);
    };
    const handleCloseApp = () => {
      setAnchorApp(null);
    };
    
    const menuToggle = () => {
      console.log(idMenuOpen);
      setIdMenuOpen(!idMenuOpen);
    }

    const productsToggle = () => {
      //console.log(idMenuOpen);
      SetProductMenu(!productsMenu);
      if(productsMenu === false){
        document.body.style.overflow ="hidden";
      }else{
        document.body.style.overflow ="";
      }
      
      
    }

    const closeProduct = () =>{
      SetProductMenu(!productsMenu);
      document.body.style.overflow ="";
    }

    const AppsToggle = () => {
      console.log(appsMenuOpen);
      setAppsMenuOpen(!appsMenuOpen);
      
    }

    function stringAvatar(name) {
      
      if(name === null){
        let appusername = "Ohene Kofi"; 
        
        return {
          sx: {
            bgcolor: stringToColor(appusername),
          },
          children: `${appusername.split(' ')[0][0]}${appusername.split(' ')[1][0]}`,
        };
      }else{
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
     
    }

    function stringToColor(string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
    
   

    // matches if the screen width is 600px or more
    const matches = useMediaQuery('(min-width:900px) and (max-width:1050px)');

    //console.log(triggerfromElevation);
    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2}}>
          iDecide 
        </Typography>
        
      
      
        <Divider />
        <List>
          {navItems.map((item,key) => (
            
              <ListItem disablePadding key={key}>
                <ListItemButton component={Link} to={item.idnm}
                  sx={{
                    textAlign: 'center',
                    color:
                      activeMenuItem && activeMenuItem.idnm === item.idnm ? '#000' : '',
                      backgroundColor:
                      activeMenuItem && activeMenuItem.idnm === item.idnm ? theme.palette.background.paper : '',
                  }}
                >
                  <ListItemText primary={item.navheading}/>
                </ListItemButton>
              </ListItem>
           
          ))}
        </List>
      </Box>
    );
  
    const container = window !== undefined ? () => window().document.body : undefined;
    //const con = window !== undefined ? () => window().scrollY: undefined;
    //console.log(con);
    // console.log("theme checl", theme);
    return (
        <>
          <CssBaseline />
            <ElevationScroll {...props} >
              <AppBar component="nav" sx={{ backgroundColor: productsMenu ? "#fff" : "none !important" , background: productsMenu ? "#fff" : "none !important"}} elevation={0}>
                <Toolbar>
                  <Stack direction="row" >
                    <Stack>
                      <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={handleDrawerToggle}
                          sx={{ mr: 2, display: { md: 'none' } }}
                      >
                          <MenuIcon />
                      </IconButton>
                    </Stack>

                  </Stack>
                    <img src={theme.palette.mode === 'light' ? LogoBlack : Logo } width="auto" height={matches ? '18px' : '25px'} />
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{ whiteSpace:"nowrap",overflow:'hidden', fontSize: matches ? '14px' : '1.3rem',width: matches ? '18px' : '', flexGrow: 1, ml: 1, textOverflow:"ellipsis", color: theme.palette.mode === "light" ? '#212529' : '#f3f3f3', display: { xs: 'block', sm: 'block', md: 'block',} }}
                    >
                        iDecide 
                    </Typography>

                    <ThemeSwitchNew triggerTheme={props.triggerTheme} modeState ={props.modeState}/>
                    <Box className="pdts" sx={{ borderLeft:"none"}} >
                      <Button onClick={productsToggle} sx={{ pr: 2,
                          '& svg': {
                            color:  theme.palette.text.primary,
                            transition: '0.2s',
                            transform: ' rotate(0)',
                          },
                          '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                              transform: 'rotate(-180deg)',
                            },
                            '& svg:last-of-type': {
                              right: 0,
                              opacity: 1,
                            },
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                          
                          },
                        }}>
                        <ExpandLessIcon sx={{  right: 4, opacity: 1, }} /> <Typography sx={{color: theme.palette.text.primary, fontWeight:500}}>PRODUCTS</Typography> 
                      </Button>
                      <div className={`pdtBox ${productsMenu ? "pdtactive" : ""}`} style={{ background:theme.palette.background.paper }}>
                        <Box sx={{ height : "50px", backgroundColor: "rgb(213,228,242,0.5)", display:"flex", flexDirection:"row" , justifyContent:"space-between", pl:2, pr:2, borderBottom: `1px solid ${theme.palette.divider}`}}>
                          <Box >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                              <Tab label="Products" {...a11yProps(0)} />
                              <Tab label="Services" {...a11yProps(1)} />
                              <Tab label="Item Three" {...a11yProps(2)} />
                            </Tabs>
                          </Box>
                          <Box sx={{paddingTop:"4px"}}>
                            <IconButton onClick={closeProduct}><CloseIcon /></IconButton>
                          </Box>
                        </Box>

                        <Box sx={{ width: '100%', }}>
                          <CustomTabPanel value={value} index={0}>
                            <Box component="div" style={{ color:"#000"}}><AppTabs /> </Box>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={1}>
                            Item Two
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={2}>
                            Item Three
                          </CustomTabPanel>
                        </Box>

                      </div>
                    </Box>

                    

                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, mr:5 }} >
                        {navItems.map((item, key) => (
                          <Button component={Link} to={item.idnm} variant="text"
                            // Use the trigger value to determine if the current section is in view and apply the active-menu-item class accordingly
                            className={`${ pathname === "/" && item.idnm === '/'? "active-menu-item":pathname === '/' + item.idnm
                              ? 'active-menu-item'
                              : '' } menubtn`}
                              key={key}
                              sx={{
                                color: theme.palette.text.primary,
                                fontSize: matches ? '12px' : '0.875rem',
                                '&:active': {
                                  backgroundColor: "none",
                                  background: "none",
                                },
                                '&:hover': {
                                  backgroundColor: "none",
                                  background: "none",
                                  color:"#6f42c1"
                                }
                              }}
                          
                          >
                            {item.navheading} 
                          </Button>
                        ))}
                    </Box>
                    <Box sx={{ mr: 1 }} className="appsholder">
                      <IconButton aria-label="delete" onClick={AppsToggle} size="small" sx={{
                            color: triggerfromElevation
                              ? theme.palette.mode === 'dark'
                                ? '#f3f3f3'
                                : '#212529'
                              : '#f3f3f3',
                            fontSize: matches ? '12px' : '0.875rem',
                          }}>
                        <AppsRoundedIcon />
                      </IconButton>
                      <div className={`appmenu ${appsMenuOpen ? "activeApp" : ""}`} style={{ background:theme.palette.background.paper }}>
                        <Stack direction="row">
                          <img src={Crm} style={{ height: "50px"}} />
                          
                        </Stack>
                      </div>
                      
                    </Box>
                  { user ? 
                  (<Stack direction="row" >
                      <Box className="action"  >
                        <Tooltip title="profile setup">
                          <div className="profile" onClick={menuToggle}>
                            <Avatar style={{ backgroundColor: "#6f42c1" }} {...stringAvatar("Ben Bekoe")} />
                          </div>
                        </Tooltip>
                        <div className={`idmenu ${idMenuOpen ? "idactive" : ""}`} style={{ background:theme.palette.background.paper }}>
                          <IconButton aria-label="delete" onClick={menuToggle} size="small" sx={{ position: "absolute" , top: 2, right:"5px"}}>
                              <CloseIcon fontSize="inherit" />
                          </IconButton>
                          <Typography variant="overline" className='idmenu_email' display="block" gutterBottom sx={{ textAlign:"center", color: theme.palette.secondary.main, }}>{user.email}</Typography>
                          
                          <Grid container spacing={2} >
                            <Grid item xs display="flex" justifyContent="center" alignItems="center" sx={{mt:2}}>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                              >
                              {/* <Avatar sx={{ bgcolor: deepPurple[500],  width: 80, height: 80 }} {...stringAvatar(user.displayName)} />*/}
                              <Avatar style={{ backgroundColor: "#6f42c1" }} sx={{  width: 80, height: 80 }} {...stringAvatar("ben bekoe")} />
                              </StyledBadge>
                            </Grid>
                          </Grid>
                          <Typography variant="body2" sx={{ textAlign: "center", width:"80%", margin:"5px auto 0 auto", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis", color:theme.palette.text.primary}}><span className='positionlabel'>CEO</span> - <span className='orglabel'>xyz company</span></Typography>
                          <Typography variant="h3" component="h3" sx={{textOverflow:"ellipsis" , whiteSpace:"nowrap", color:theme.palette.text.primary}}>
                            { user.displayName }
                          </Typography>
                          <div style={{ display: 'flex', flexDirection:'row', mb:4}} >
                            <Button variant="outlined" href="https://accounts.idects.com?redirect=idectsindex" sx={{ marginLeft:"auto", order:2}} >sign Out</Button>
                            <Button variant="outlined" href="https://accounts.idects.com/" >Manage your account</Button>
                            
                          </div>
                          
                        </div> 
                      </Box>     
                    </Stack>) 
                  :
                  ( <Box sx={{  display: { xs: 'none', sm: 'none', md: 'block' } }}>
                      <Button className='btn_auth' variant="outlined" sx={{"&:focus": { outline: "none" }}}  style={{ marginRight: '10px',  color: theme.palette.mode === 'dark' ? '#f3f3f3' : "#212529" }}  size="sm" href="https://accounts.idects.com?redirect=idectsindex"> {t('options.signin')}</Button>
                      <Button className='btn_auth' variant="outlined"  sx={{"&:focus": { outline: "none" }}}  style={{ color: theme.palette.mode === 'dark' ? '#f3f3f3' : "#212529" }}   size="sm" href="https://accounts.idects.com/signup"> {t('options.signup')}</Button>
                    </Box> ) }
                          
                </Toolbar>
              </AppBar>
            </ElevationScroll>    
  
            <nav>
            <Toolbar id="back-to-top-anchor" style={{ width:0, minHeight: 0, maxHeight: 0,paddingLeft:'0px',paddingRight:'0px', margin:'none', color:'none', backgroundColor:'none', zIndex: 0 }} />
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          
        </>
    );
}
