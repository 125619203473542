import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ListItemButton from '@mui/material/ListItemButton';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import TranslateIcon from '@mui/icons-material/Translate';
import { uiActionCreators, langActionCreators } from './../../store/actions';
import { useTranslation, Trans } from 'react-i18next';

const langs = ['English', 'French', 'Deutsch'];

function SimpleDialog(props) {
  
  const { onClose, selectedValue, open } = props;
  
  const handleClose = () => {
    
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    //console.log(value);
   
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set Language</DialogTitle>
      <List sx={{ pt: 0 }}>
        {langs.map((lang) => (
          <ListItem disableGutters key={lang}>
            <ListItemButton onClick={() => handleListItemClick(lang)}>
              
              <ListItemText primary={lang} />
            </ListItemButton>
          </ListItem>
        ))}
        
      </List>
    </Dialog>
  );
}

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    //console.log('ghana');
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const Footer = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);

  const [isSwitchToggle, setIsSwitchToggle] = useState(false);
  const [themeMode, setThemeMode ]  = useState(uiState.theme);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(langs[1]);

  const uiThemeMode = uiState.theme;
  const { getTheme, setTheme } = bindActionCreators(uiActionCreators, dispatch);
  const { getLang, setLang } = bindActionCreators(langActionCreators, dispatch);

  const { t, i18n } = useTranslation();

  console.log(uiThemeMode);
  const toggleSwitcher = () => {
    setIsSwitchToggle(!isSwitchToggle);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    
    let lang = '';
    if ((value.toLowerCase()).trim() === 'english') {
      lang = 'en';
    } else if ((value.toLowerCase()).trim() === 'french') {
      lang = 'fr';
    }else if((value.toLowerCase()).trim() === 'deutsch'){
      lang = 'de';
    }else{
      lang = 'en';
    }

    setLang(lang);
    setOpen(false);
    localStorage.setItem('ixuexhterui', lang);
    setSelectedValue(lang);
    i18n.changeLanguage(lang)
    //console.log(value);
  };

  

  const handleThemeSwitch = () => {
    let newtheme = '';

    console.log(uiThemeMode);
    

    if (uiThemeMode === 'light') {
      newtheme = 'dark';
    } else if (uiThemeMode === 'dark') {
      newtheme = 'light';
    } else {
      newtheme = uiThemeMode;
    }
    setThemeMode(newtheme);
    //setModeTheme(newtheme);
    setTheme(newtheme);
    localStorage.setItem('ixtohvcrsdg',newtheme);
     console.log(newtheme);
  };

  const iconStyles = {
    color: "f3f3f3",
    transition: 'color 0.3s ease-in-out',
  };

  const showTransBox =() => {
    handleClickOpen();
  }


  return (
    <>
      {/* Footer Start */}
      <Box component='footer' className={`section footer`} style={{backgroundColor:"#212121"}}>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Container>
          <Row>
            <div className="text-center">
              <ul className="list-inline social mb-0">
                <li className="list-inline-item">
                  <Link to="#" className="social-icon text-muted me-1">
                    <i className="ti-facebook"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-icon text-muted me-1">
                    <i className="ti-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-icon text-muted me-1">
                    <i className="ti-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-terms">
              <ul className="mb-0 list-inline text-center mt-4 pt-2">
                <li className="list-inline-item">
                  <Link to="#" className="text-muted">
                    Terms & Condition
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="text-muted">
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="text-muted">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-4 pt-2 text-center">
              <p className="copy-rights text-muted mb-0">
                {new Date().getFullYear()} © iDecide Techscape. Design by iDecide Techscape
              </p>
            </div>
          </Row>
        </Container>
      </Box>
      {/* Footer End */}

      {/* scrollup button */}
     

      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
     
    </>
  );
};

export default Footer;
