import React, { Component } from "react";
import { Col, Container, Form, Input, Row, Button } from "reactstrap";
import { Box,Typography,Grid,Paper,TextField, TextareaAutosize, Stack } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";

 const Contact = () => {
    const theme = useTheme();

    return (
      <React.Fragment>
        <Box component='section' className="section" id="contact">
          <Grid container spacing={3} sx={{p: 8}}>
            <Grid item sx={12} md={6} >
              imGE
            </Grid>
            <Grid item sx={12} md={6} >
              <Stack spacing={2} direction={"row"} sx={{ width:"100%"}} justifyContent="space-between">
                <TextField variant="standard" label="Your Name" sx={{ width:"50%"}}/>
                <TextField variant="standard" label="Phone Number" sx={{ width:"50%"}} type="number"/>              
              </Stack>
              <Box sx={{ mt: 1 }}>
                <TextField variant="standard" label="E-mail" fullWidth sx={{
                  '& .MuiInputBase-input MuiInput-input': {
                    borderBottom: 'none',
                    '&:before': {
                      borderBottom: '1px solid #ccc',
                    },
                    '&:after': {
                      borderBottom: '2px solid #007bff',
                    },
                  },
                }}/>
              </Box>
              <Box sx={{ mt: 1 }}>
                <TextField
                fullWidth
                  id="standard-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  defaultValue="Message"
                  variant="standard"
                />
              </Box>
              
            </Grid>
          </Grid>
          
          {/*
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={6}>
                <i className="ti-headphone-alt title-icon text-muted"></i>
                <Typography variant="h3" className="title">
                   <span className="fw-bold">Get In Touch</span>
                </Typography>
                <Typography variant="p" className="text-muted mt-3 title-subtitle mx-auto">
                  It is a long established fact that a reader will be of a page
                  when established fact looking at its layout.
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className="mt-4 pt-4">
                  <p className="text-muted mt-4">
                    {" "}
                    <span className="fw-bold ">Customer care:</span>
                    <br /> <span className="d-block mt-2">+233 50 4371696</span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold ">Office Address:</span>
                    <br />{" "}
                    <span className="d-block mt-2">
                      974/9 Knife street Kasoa
                    </span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold ">Email Address:</span>
                    <br /> <span className="d-block mt-2">idecidetechscape@gmail.com</span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold ">Office Time:</span>
                    <br />{" "}
                    <span className="d-block mt-2">9:00AM To 6:00PM</span>
                  </p>
                </div>
              </Col>
              <Col lg={8}>
               
                <Box style={{ marginTop: '60px'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Subject"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        multiline
                        maxRows={4}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Button variant="contained" color="primary">
                      Send Message
                      </Button>
                     
                    </Grid>
                  </Grid>
                </Box>
              </Col>
            </Row>
          </Container>
          
          */}
        </Box>
      </React.Fragment>
    );
 
}

export default Contact;