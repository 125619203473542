import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          section: {
            part1: 'Our business success depends largely on helping your business to succeed. An enganging platform focus on bringing you a efficient-deriven processes.  We empower your business with our engaging platform to make data-driven decisions. ',
            part2: 'Now technology is not an option, it is a necessity. That is what we provide: all the tools you need to grow your business and exceed your objectives. We are here just for you.'
          },
          menu:{
            home: 'Home',
            services: "Services",
            features: "Features",
            client: "Client",
            team: "Team",
            faq: "FAQ",
            pricing: "Pricing",
            contact: "Contact"
          },
          options:{
            subscribe: "Subscribe",
            signin :"Sign In",
            signup: "Sign Up"
          }
        }
      },
      fr: {
        translation: {
          section: {
            part1: 'Notre travail de toute une vie vous aide à atteindre votre objectif de vie. Une plateforme engageante, enracinée dans la réalisation de processus efficaces.',
            part2: 'Maintenant, la technologie n\'est pas une option, c\'est une nécessité. C\'est ce que nous fournissons : tous les outils dont vous avez besoin pour faire croître votre entreprise et dépasser vos objectifs. Nous sommes là juste pour vous.'
          },
          menu: {
            home: 'Accueil',
            services: 'Services',
            features: 'Fonctionnalités',
            client: 'Client',
            team: 'Équipe',
            faq: 'FAQ',
            pricing: 'Tarification',
            contact: 'Contact'
          },
          options: {
            subscribe: 'S\'abonner',
            signin: 'Se connecter',
            signup: 'S\'inscrire'
          }
        }
      },
      de: {
        translation: {
          section: {
            part1: 'Unsere Lebensarbeit hilft Ihnen, Ihr Lebensziel zu erreichen. Eine fesselnde Plattform, die auf effiziente Prozesse ausgerichtet ist.',
            part2: 'Technologie ist jetzt keine Option mehr, sondern eine Notwendigkeit. Das ist das, was wir bieten: alle Werkzeuge, die Sie benötigen, um Ihr Unternehmen zu entwickeln und Ihre Ziele zu übertreffen. Wir sind nur für Sie da. Subscribe for news and product updates from use.'
          },
          menu: {
            home: 'Startseite',
            services: 'Dienstleistungen',
            features: 'Funktionen',
            client: 'Kunde',
            team: 'Team',
            faq: 'FAQ',
            pricing: 'Preise',
            contact: 'Kontakt'
          },
          options: {
            subscribe: 'Abonnieren',
            signin: 'Anmelden',
            signup: 'Registrieren'
          }
        }
      }
    }
  });

export default i18n;