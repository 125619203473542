import React, { useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Box,Typography,Grid,Paper } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Pricing = () => {
  const theme = useTheme();

  const [pricingData] = useState([
    {
      price: "$29",
      bandwidth: "1GB",
      onlinespace: "50MB",
      support: "no",
      domain: 1,
    },
    {
      price: "$59",
      bandwidth: "2GB",
      onlinespace: "500MB",
      support: "Yes",
      domain: 5,
      isActive: true,
    },
    {
      price: "$79",
      bandwidth: "4GB",
      onlinespace: "1 GB",
      support: "yes",
      domain: 10,
    },
  ]);

  const PricingBox = pricingData.map((item, key) => (
      <Grid item md={4} xs={12} sm={4} key={key}>
        <Card  variant="outlined" >
          <CardContent>
            <Box >
              <div className="plan-price fw-bold" style={{ textAlign:'center'}}>
                <h1 className="mb-0 fw-bold">{item.price}</h1>
                <p className="mb-0">Per Month</p>
              </div>
              <div className="plan-features text-muted mt-5 mb-5" style={{ textAlign:'center'}}>
                <p>
                  Bandwidth: <b className="text-primary">{item.bandwidth}</b>
                </p>
                <p>Onlinespace: {item.onlinespace}</p>
                <p>Support: {item.support}</p>
                <p>{item.domain} Domain</p>
                <p className="mb-0">No Hidden Fees</p>
              </div>
              <div style={{ textAlign:'center'}}>
                <Link to="#" className="btn btn-primary btn-round">
                  Join Now
                </Link>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
  ));

  return (
    <React.Fragment>
      <Box component='section' className="section " id="pricing">
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg={12}>
              <i className="ti-package title-icon text-muted"></i>
              <Typography variant='h3' className="title">
                 <span className="fw-bold">Pricing</span>
              </Typography>
              <Typography component='p' className="text-muted mt-3 title-subtitle mx-auto" sx={{ marginBottom: '50px'}}>
                It is a long established fact that a reader will be of a page
                when established fact looking at its layout.
              </Typography>
            </Col>
          </Row>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {PricingBox}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Pricing;
