import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Box,Typography,Grid,Paper } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTheme, experimentalStyled as styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Team = () => {
  const theme = useTheme();

  const teamData = [
    {
      profile: "assets/images/team/team-3.png",
      name: "@Donald Laughlin",
      description:
        "You want customer to your store. Easily your coupans and has Clooger.",
    },
    {
      profile: "assets/images/team/team-2.png",
      name: "@Rigoberto Valenza",
      description:
        "You want customer to your store. Easily your coupans and has Clooger.",
      isActive: true,
    },
    {
      profile: "assets/images/team/team-1.png",
      name: "@Venessa Smith",
      description:
        "You want customer to your store. Easily your coupans and has Clooger.",
    },
  ];

  return (
    <Box component="section" className="section" id="team">
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={12}>
            <i className="ti-user title-icon text-muted"></i>
            <Typography variant="h3" className="title">
               <span className="fw-bold">Our Team</span>
            </Typography>
            <Typography component='p' className="text-muted mt-3 title-subtitle mx-auto" sx={{ marginBottom: '50px'}}>
              It is a long established fact that a reader will be of a page
              when established fact looking at its layout.
            </Typography>
          </Col>
        </Row>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {teamData.map((team, key) => (
           
              <Grid item md={4} xs={12} sm={4} key={key}>
                <Card  variant="outlined" >
                  <CardContent>
                    <Box>
                    <div className="">
                      <img
                        src={team.profile}
                        alt=""
                        className="img-fluid rounded-circle mx-auto d-block"
                      />
                    </div>
                    <div className="team-name" style={{ textAlign:'center'}}>
                      <Typography variant="body2" className="fw-bold mb-0 mt-4">{team.name}</Typography>
                      <Typography className="text-muted mt-4">{team.description}</Typography>
                    </div>
                    <div className="">
                      <ul className="list-inline team-social mt-4 mb-0" style={{ textAlign:'center'}}>
                        <li className="list-inline-item">
                          <Link to="#">
                            <i className="ti-github"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <i className="ti-skype"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <i className="ti-twitter-alt"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Team;
