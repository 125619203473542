import React from 'react';


function Errors(){

    return (
        <div role="alert">
          <p>Something went wrong:</p>
          <pre style={{ color: "red" }}>some errors</pre>
        </div>
      );
}

export default Errors;