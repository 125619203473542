export const getLang = (theme) => {
  return (dispatch) => ({
    type: 'SET_LANG',
    payload: theme,
  });
};

export const setLang= (theme) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_LANG',
      payload: theme,
    });
  };
};

export const getSelected = (index) => {
  return (dispatch) => ({
    type: 'SET_SELECTED',
    payload: index,
  });
};

export const setSelected = (index) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_SELECTED',
      payload: index,
    });
  };
};
