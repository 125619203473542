import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Box, Typography } from "@mui/material";

export default class Services extends Component {
  render() {
    return (
      <React.Fragment>
        <Box component="section" className="section" id="services">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <i className="ti-ruler-pencil title-icon text-muted"></i>
                <Typography variant="h3" className="title">
                   <span className="fw-bold">Services</span>
                </Typography>
                
                <Typography component='p' className="text-muted mt-3 title-subtitle mx-auto">
                  providing world class solutions to business issues. Taking the load off from you.
                  Choose from one of the apps below and lets work together
                </Typography>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-settings text-primary"></i>
                  </div>
                  <div className="mt-3">
                    
                      <h5 className="services-title fw-bold mb-3">
                      <Typography> CRM</Typography>
                      </h5>
                      <p className="services-subtitle text-muted">
                        {" "}
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </p>
                    
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-palette text-primary"></i>
                  </div>
                  <div className="mt-3">
                   
                      <h5 className="services-title fw-bold mb-3">
                      <Typography> HR Management</Typography>
                      </h5>
                    
                    
                    <p className="services-subtitle text-muted">
                    {" "}
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-stats-up text-primary"></i>
                  </div>
                  <div className="mt-3">
                  
                    <h5 className="services-title fw-bold mb-3">
                    <Typography>App Solution 3</Typography>
                    </h5>
                    <p className="services-subtitle text-muted">
                    {" "}
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-package text-primary"></i>
                  </div>
                  <div className="mt-3">
                    
                    <h5 className="services-title fw-bold mb-3">
                    <Typography>App Solution 4</Typography>
                    </h5>
                    <p className="services-subtitle text-muted">
                    {" "}
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-dashboard text-primary"></i>
                  </div>
                  <div className="mt-3">
                    
                      <h5 className="services-title fw-bold mb-3">
                      <Typography>Solution 7</Typography>
                      </h5>
                      <p className="services-subtitle text-muted">
                      {" "}
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </p>
                    
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-headphone text-primary"></i>
                  </div>
                  <div className="mt-3">
                  
                    <h5 className="services-title fw-bold mb-3">
                    <Typography>Solution 9</Typography>
                    </h5>
                    <p className="services-subtitle text-muted">
                    {" "}
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Box>
      </React.Fragment>
    );
  }
}
