const dark = {
  palette: {
    mode: 'dark',
  },
  typography: {
    caption: {
      fontSize: '0.7rem',
      fontWeight: 400,
      letterSpacing: '0.03em',
      lineHeight: 1.66,
      fontFamily: 'Roboto',
    },
    overline: {
      fontFamily: 'Roboto',
      fontSize: '0.7rem',
      fontWeight: 400,
      lineHeight: 2.68,
      letterSpacing: '0.07em',
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: '0.9rem',
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.01em',
    },
    sliderbody2: {
      fontFamily: 'Roboto',
      fontSize: '0.9rem',
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.01em',
    },
    body1: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.51,
      letterSpacing: '0.01em',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '0.9rem',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.01em',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.74,
      letterSpacing: '0.01em',
    },
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    bannerHead: {
      fontSize: '3.7rem',
      fontWeight: 700,
      lineHeight: 1.16,
      fontFamily: "Raleway",
    },
    h1: {
      fontSize: '3.2rem',
      fontWeight: 700,
      lineHeight: 1.16,
      fontFamily: "Raleway",
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 1.2,
      fontFamily: "Raleway",
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: 1.16,
      fontFamily: "Raleway",
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: 1.23,
      fontFamily: "Raleway",
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: 1.16,
      fontFamily: "Raleway",
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: 1.16,
      fontFamily: "Raleway",
    },
    button: {
      fontSize: '0.9rem',
      fontWeight: 500,
      lineHeight: 1.76,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

export default dark;
