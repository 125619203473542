import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/raleway"; // Defaults to weight 400
import "@fontsource/raleway/400.css"; // Specify weight
import "@fontsource/raleway/400-italic.css"; // Specify weight and style
import "@fontsource/raleway/500.css"; 
import "@fontsource/raleway/600.css"; 
import "@fontsource/raleway/700.css"; 
import "@fontsource/raleway/800.css"; 
import "@fontsource/raleway/900.css"; 
import "@fontsource/exo-2"; // Defaults to weight 400
import "@fontsource/exo-2/400.css"; // Specify weight
import "@fontsource/exo-2/400-italic.css"; // Specify weight and style
import "@fontsource/exo-2/500.css"; // Specify weight
import "@fontsource/exo-2/500-italic.css"; // Specify weight and style
import "@fontsource/exo-2/600.css"; // Specify weight
import "@fontsource/exo-2/600-italic.css"; // Specify weight and style
import "@fontsource/exo-2/700.css"; // Specify weight
import "@fontsource/exo-2/700-italic.css"; // Specify weight and style
import "@fontsource/exo-2/800.css"; // Specify weight
import "@fontsource/exo-2/800-italic.css"; // Specify weight and style
import "@fontsource/exo-2/900.css"; // Specify weight
import "@fontsource/exo-2/900-italic.css"; // Specify weight and style
import { Provider } from 'react-redux';
import configureStore from './store/store';
import './i18/i18n';
/**
 * body {
  font-family: "Exo 2";
}
 */

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore();

//set theme
if (localStorage.getItem('ixtohvcrsdg') !== null) {
  // The user_name key is set.
  console.log('theme already here');
  console.log(localStorage.getItem('ixtohvcrsdg'));
} else{
  localStorage.setItem('ixtohvcrsdg','light');
  console.log('theme set');
}

//set lang
if (localStorage.getItem('ixuexhterui') !== null) {
  // The user_name key is set.
  console.log('lang already here');
  console.log(localStorage.getItem('ixuexhterui'));
} else{
  localStorage.setItem('ixuexhterui','light');
  console.log('lang set');
}




root.render(
  <Provider store={store}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>,
);
