import React, { useState, lazy, Suspense,useEffect } from 'react';
import routes from "./routes";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dark from './themes/dark';
import light from './themes/light';
import { actionCreators, uiActionCreators } from './store/actions';
import { AuthFirebaseContextProvider, AuthFirebaseContext } from './Context/AuthFirebaseContext';
import './firebase/Firebase';
import { ErrorBoundary } from "react-error-boundary";
import  Errors  from "./utils/Errors.js";
import "./app.css";

// import "../../../public/assets/css/themify-icons.css";
// const SettingsLayout = lazy(() => import('./Components/Layout/config/SettingsLayout.jsx'));
const Main = lazy(() => import("./component/Layouts/Main.js"));
const Home = lazy(() => import("./pages/Home/Home.js"));
const Products = lazy(() => import("./pages/Products/Products.js"));


const themeSetter = (options) => {
  let themeselector;

  if (options === 'light') {
    themeselector = light;
  } else if (options === 'dark') {
    themeselector = dark;
  }
  return themeselector;
};



function App() {
  const uiState = useSelector((state) => state.ui);
  const [initialTheme, setInitialTheme] = useState();
  const dispatch = useDispatch();
 
  const uiThemeMode = themeSetter(uiState.theme);
  const themetest = createTheme(uiThemeMode);
  
  //console.log(uiState);
  const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
  };

  useEffect(() => {

    const fetchtheme = localStorage.getItem('userthemes'); 
  
    if(!fetchtheme){
      setInitialTheme("light");
      localStorage.setItem('userthemes', "light"  );
    }else{
      setInitialTheme(fetchtheme);
    }
    
    // console.log(fetchtheme);
  }, []);

  const LoadComponent = ({ component: Component }) => (
    <ErrorBoundary fallback={fallbackAuthRender} onError={logError}>
      <Suspense fallback={loading()}>
          <Component />
      </Suspense>
    </ErrorBoundary>
  );

  const loading = () =>{
    return '<div>loading</div>'
  }
 
  function fallbackAuthRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert">
        <p>Something went wrong in login activities.. i will style later:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }
  
  return (
    <React.Fragment> 
      
        <AuthFirebaseContextProvider>  
          <Routes>
          
              <Route  element= {<LoadComponent component={Main}  />} >
                <Route path="/"  element = <LoadComponent component={Home}/> />
                <Route path="/products"  element = <LoadComponent component={Products}/> />
                {/*
                  <Route  errorElement={<SettingsErrorPage />}>
                  <Route index element={<Welcome />} />
                  <Route path="personaldetails"  element = <LoadComponent component={PersonalInfo}/> />
                  
                  <Route  path="*" element={<ErrorPage />} />
                </Route>
                */}
                {/*
                <Route  errorElement={<SettingErrorPage />}>
                
                <Route path="contacts/:contactId"  element: <LoadComponent component={Contacts}/> />
                <Route path="contacts/:contactId/edit" element={<EditContact />}  />
                <Route path="contacts/:contactId/destroy" action={destroyAction} />
                  */}
              </Route>
          </Routes>
        {/*
          <ErrorBoundary FallbackComponent={Errors} onError={logError}> 
            <Routes>
              {routes.map((route, idx) => (
                <Route path={route.path} element={route.component} key={idx} />
              ))}
            </Routes>
              </ErrorBoundary> */}
        </AuthFirebaseContextProvider>
      
    </React.Fragment>
  )
  
}

export default App;